<template>
  <div class="main">
    <van-nav-bar
      :title="title"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    >
      <template #right>
        <van-tag type="primary" round style="font-size:14px" plain
          >我的预约</van-tag
        >
      </template>
    </van-nav-bar>

    <!-- <van-swipe
      class="my-swipe"
      :autoplay="3000"
      indicator-color="white"
      style="height:150px;backgroundColor:#ccc"
    >
      <van-swipe-item v-for="item in list" :key="item.id">1</van-swipe-item>
    </van-swipe> -->
    <!-- <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    > -->
    <van-cell
      icon="location-o"
      size="large"
      v-for="item in list"
      :key="item.id"
      :title="item.brand"
      :value="item.address"
      :label="item.peopleNumber"
      @click="toClick(item.id)"
    >
      <template #title>
        <span class="custom-title">{{ item.brand }} </span>
        <!-- <van-tag type="danger">{{ item.peopleNumber }}</van-tag> -->
      </template>
    </van-cell>
    <!-- </van-list> -->
  </div>
</template>

<script>
import { getListAPI } from "../../api/reserveMeeting";
export default {
  data() {
    return {
      obj: {
        current: 1,
        pageSize: 1000,
        filterData: {
          status: 1,
        },
      },
      // id: "",
      title: "会议室",
      list: [],
      loading: false,
      finished: true,
    };
  },
  computed: {},
  created() {
    this.initList();
  },
  watch: {},
  methods: {
    async initList() {
      const result = await getListAPI(this.obj);
      console.log(result);
      let { data } = result.data;
      this.list = data;
    },
    toClick(id) {
      this.$router.push(`/reservemeeting/meetingdetail?id=${id}`);
    },

    onClickLeft() {
      this.$router.back();
    },
    onClickRight() {
      this.$router.push("/reservemeeting/myappointment");
    },
    onLoad() {},
  },
};
</script>

<style lang="less" scoped>
.main {
  overflow-y: auto;
  height: 100%;
  box-sizing: border-box;
}
</style>
