import request from "@/utils/request";
// 会议室列表
export const getListAPI = (obj) => {
  return request.post("/api/MeetingRoom/Page", obj);
};
// 会议室细节
export const getMeetingDetailAPI = (id) => {
  return request.get(`/api/MeetingRoom/Get?id=${id}`);
};
// 会议室图片
export const getMeetingRoomImgAPI = (id) => {
  return request.post("/api/MeetingRoomImg/GetList", id);
};
// 会议室套餐
export const getMeetingRoomComboAPI = (obj) => {
  return request.post("/api/MeetingRoomCombo/Page", obj);
};
// 会议室场地费
export const getMeetingRoomVenueAPI = (obj) => {
  return request.post("/api/MeetingRoomVenue/Page", obj);
};

// 预约
export const getMeetingRoomReservedCreateAPI = (obj) => {
  return request.post("/api/MeetingRoomReserved/Create", obj);
};
// 已经预约时间表
export const getMeetingRoomReservedDateListAPI = (obj) => {
  return request.post("/api/MeetingRoomReserved/GetReservedDateList", obj);
};
// 我的预约
export const getMeetingRoomReservedMyPageAPI = (obj) => {
  return request.post("/api/MeetingRoomReserved/MyPage", obj);
};
// 取消预约
export const getMeetingRoomReservedCancleAPI = (id) => {
  return request.get(`/api/MeetingRoomReserved/Cancle?id=${id}`);
};
// 提前结束
export const finishMeetingRoomReserved = (id) => {
  return request.get(`/api/MeetingRoomReserved/Finish?id=${id}`);
};
// 当前登录用户的部门是否会议室vip部门
export const getIsMeetingRoomVipAPI = () => {
  return request.get("/api/HRDepartment/GetIsMeetingRoomVip");
};

//预约详细-预约讯息
export const getMeetingRoomReservedInfo = (id) => {
  return request.get(`/api/MeetingRoomReserved/Get?id=${id}`);
}
